
import Vue from "vue";
import * as types from "@/store/mutation-types";
import { objectDifference } from "@/helpers";
import {
  faqMapActions,
  faqMapGetters,
  faqMapMutations,
  faqMapState
} from "@/store/modules/faq";
import { atlasfileMapActions } from "@/store/modules/atlasfile";
import {
  atlasPlatformMapActions,
  atlasPlatformMapGetters
} from "@/store/modules/atlasPlatform";
import { IAtlasPlatform } from "@/store/modules/atlasPlatform/types";

export default Vue.extend({
  name: "SingleFAQ",
  data() {
    return {
      resourceNotFound: false,
      errorMessage: "",
      isLoading: false,
      isAdmin: true,
      successMessage: "",
      loadingText: "",
      attachmentId: "",
      file: null,
      formData: new FormData(),
      isSaving: false
    };
  },
  components: {
    ContentEditor: () => import("@/components/TinyEditor/TinyEditor.vue")
  },
  created() {
    this.getFetchAtlasPlatforms().then();
    this.getFaq(this.faqId)
      .then(data => {
        this.SET_EDIT(data);
      })
      .catch(error => {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      });
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  },
  methods: {
    ...faqMapActions(["getFaq", "updateFaq"]),
    ...faqMapMutations(["SET_EDIT"]),
    ...faqMapMutations({ editField: types.SET_EDIT_FIELD }),
    ...atlasfileMapActions(["createAtlasfile"]),
    ...atlasPlatformMapActions(["getAtlasPlatforms"]),
    async getFetchAtlasPlatforms() {
      try {
        await this.getAtlasPlatforms({});
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    },

    createFormData(file: any) {
      const formData = new FormData();
      formData.append("file", file.blob(), file.filename());
      formData.append("name", "");
      formData.append("isPublic", "false");
      formData.append("category", "None");
      formData.append("type", "attachment");
      return formData;
    },
    async uploadFunction(file: any) {
      this.file = file;
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "cancelEditFaq":
          this.faqCallback();
          break;
        case "update":
          this.updateCallback();
          break;
      }
    },
    async faqCallback(): Promise<any> {
      this.$router.push("/admin/faqs").catch(() => {});
    },
    async doSave() {
      try {
        await this.updateFaq({
          id: this.faqId,
          update: this.updatedFields
        });
        this.isSaving = false;

        this.$router.replace("/admin/faqs");
        this.SET_EDIT({} as any);
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    async handleChange(value: string) {
      this.editField({ key: "answer", value });
      if (this.isSaving) {
        this.doSave();
      }
    },
    async updateCallback(): Promise<void> {
      try {
        this.loadingText = "Creating FAQ. Please wait...";
        this.isLoading = true;
        if (this.file) {
          const response = await this.createAtlasfile(
            this.createFormData(this.file)
          );
          this.attachmentId = response._id;
          this.editField({ key: "attachments", value: [response._id] });
          this.isSaving = true;
        } else {
          this.doSave();
        }
      } catch (err) {
        this.errorMessage = err.message;
        this.$bugSnagClient.notify(err);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    }
  },
  computed: {
    ...faqMapGetters(["getFaqById", "editingFaq"]),
    ...atlasPlatformMapGetters(["atlasPlatforms"]),
    ...faqMapState(["makingApiRequest", "editing"]),
    atlasPlatformOptions(): {
      label: string;
      value: string;
    }[] {
      const options = this.atlasPlatforms.map((platform: IAtlasPlatform) => {
        return {
          label: platform.name,
          value: platform._id
        };
      });
      return options;
    },
    faqId(): string {
      return this.$route.params.faqId;
    },
    faq(): any {
      return this.getFaqById(this.faqId);
    },
    updatedFields(): any {
      const edited = this.editing;
      const original = this.faq;
      return edited && original ? objectDifference(edited, original) : {};
    }
  }
});
