var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"element-loading-text":_vm.loadingText,"showSearch":false,"topActionButtonPrimary":{
    text: 'Update',
    key: 'update',
    disabled: Object.keys(_vm.updatedFields).length === 0,
    loading: _vm.makingApiRequest
  },"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancelEditFaq',
    disabled: _vm.isLoading
  }},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.errorMessage = ''}}},[(!_vm.resourceNotFound)?[_c('div',{staticClass:"mb-8 md:mb-12 flex justify-between"},[_c('div',[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900"},[_vm._v(" Edit FAQ ")]),_c('p',{staticClass:"mt-1 text-sm text-gray-500"},[_vm._v(" Modify the FAQ details below. ")])])]),_c('SelectField',{ref:"atlasPlatform_field",attrs:{"label":"Select Atlas Platform","fixedLabels":true,"filterable":true,"options":_vm.atlasPlatformOptions,"required":true,"selected":_vm.editing ? _vm.editing?.atlasPlatformIds : [],"multiple":""},on:{"change":function($event){return _vm.editField({ key: 'atlasPlatformIds', value: $event })}}}),_c('TextField',{ref:"question",attrs:{"label":"Question","placeholder":'Type your question here',"value":_vm.editing?.question,"required":true},on:{"input":function($event){return _vm.editField({ key: 'question', value: $event })}}}),_c('div',[_c('label',{staticClass:"font-medium text-gray-600"},[_vm._v("Answer")]),_c('ContentEditor',{key:_vm.faqId,ref:"editor",attrs:{"editorFor":"answer","imagePreviewToken":"","initialData":_vm.editing?.answer,"label":"Answer","uploadFn":_vm.uploadFunction,"attachmentId":_vm.attachmentId},on:{"change":_vm.handleChange}})],1)]:_c('ResourceNotFound',{attrs:{"title":"Resource not found.","subtitle":"Sorry, the FAQ you were looking for could not be found.","actionButtonTitle":"Back to FAQ","actionRedirectPath":"/admin/faqs"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }